import { getDefaultOptions } from 'API/auth'
import { getUrl } from 'API/getUrl'
import axios from 'axios'
import { Link } from 'gatsby'
import { navigate } from 'gatsby-link'
import React, { useEffect } from 'react'

// function to remove duplicates from an array
function removeDuplicates(originalArray: Array<any>, prop: string) {
	const newArray = []
	const lookupObject: Record<string, any> = {}

	for (const i in originalArray) {
		lookupObject[originalArray[i][prop]] = originalArray[i]
	}

	for (const i in lookupObject) {
		newArray.push(lookupObject[i])
	}
	return newArray
}

const LiveLectures: React.FC = () => {
	const [lectures, setLectures] = React.useState<any[]>([])
	useEffect(() => {
		axios
			.get(getUrl('asset/live_lectures'), {
				...getDefaultOptions(),
			})
			.then((resp) => {
				console.log({ data: resp.data })
				setLectures(removeDuplicates(resp.data, 'uid'))
			})
			.catch((err) => console.error({ err }))
	}, [])

	return (
		<div>
			<div className='container mt-2 mb-2' style={{ height: '100%' }}>
				<div className='row'>
					<div className='col-12'>
						{lectures.map((elem) => {
							const redirectLink = `/course/${elem.course}/activities/lecture/${elem.uid}`
							return (
								<div
									onClick={() => {
										navigate(redirectLink)
									}}
									className='media p-2'
									key={elem.uid}
								>
									<div className='row'>
										<div className='col-8'>
											<h3 className='mediaTitle pl-md-0'>
												<>
													<Link to={redirectLink}>{elem.name}</Link>
												</>
											</h3>
										</div>
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</div>
	)
}

export default LiveLectures
